.project {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 80px;
    padding-right: 80px;
  }

  .description {
    width: 60%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.description p {
  font-size: 20px;
  color: #3e497a;
}
  
  .project h1 {
    margin-top: 40px;
    margin-bottom: 40px;
    color: #3e497a;
    font-size: 35px;
  }
  .project img {
    width: 700px;
    border-radius: 10px;
  }
  
  .project p {
    margin-top: 20px;
    font-size: 20px;
    color: #3e497a;
  }
  
  .project svg {
    font-size: 60px;
    color: #3e497a;
  }