.footer {
    width: 100%;
    height: 200px;
    background: #21325e;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 200px;
    padding-bottom: 0px;
    margin-bottom: 0px;

    position: relative;
    left: 0;
    bottom: 0;
  }
  
  .socialMedia svg {
    color: white;
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
  }
  
  .footer p {
    color: white;
  }
  
  @media only screen and (max-width: 900px) {
    .footer svg {
      font-size: 50px;
    }
  }