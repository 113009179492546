.main {
    font-size: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.description {
    width: 60%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}




.main h1 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.main h2 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.main p {
    margin-top: 40px;
    margin-bottom: 40px;
}

.list {
    font-size: 18px;
}
