.contact {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 410px);
  }

.contact h1 {
    margin-top: 31px;
    margin-bottom: 9px;
  }

.contact h2 {
    margin-top: 34px;
  }

.contact p {
    font-size: 20px;
}

